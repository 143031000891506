.athira-MainHeading{
    width: 80%;
    height: auto;
}

.athira-MainHeading img{
    width: 80%;
    padding: 0 10%;
}

.athira-MainHeading h1{
    color: #301934;
    height: 10rem;
    padding:3rem;

}
.athira-MainHeading p{
    font-size: 1.2rem;
    font-weight: 200;
    color:#353935;
    padding: 0.5rem 4rem;

}

.athira-MainHeading h4{
    font-size: 1.5rem;
    padding: 0.5rem 1.5rem;
}

.athira-MainHeading h6{
    font-size: 1.5rem;
    padding: 0.5rem 5rem;
}

.athira-MainHeading table,td,th {
    border: 2px solid #343434;
    text-align: left;
    
}

.athira-MainHeading table{
    border-collapse: collapse;
    width: 60%;
    margin: 2% 10%;

}

.athira-MainHeading th,td{
    padding: 10px;
    font-size: 1.2rem;
}

.athira-MainHeading ul{
    list-style-type: square;
    padding: 0.5rem 5rem;
    font-size: 1.2rem;
}

.wordHighlight{
    color: red;
    font-weight: bolder;
    text-decoration: underline;
}


@media screen and (max-width:768px) {
    .athira-MainHeading{
        width: 100%;
    }
    .athira-MainHeading h1{
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        padding: 10px 20px;
        font-size: 1.2rem;
        height: 4rem;
    }
    .athira-MainHeading p {
        padding: 5px 15px;
        font-size: 1rem;
    }
    .athira-MainHeading h4{
        padding:  5px;
        font-size: 1.2rem;
    }
    .athira-MainHeading h6{
        padding: 5px 30px;
        font-size: 1.2rem;
        
    }
    .athira-MainHeading table{
        border-collapse: collapse;
        width: 90%;
        margin: 2% 5%;
    
    }
    .athira-MainHeading img{
        width: 100%;
        padding: 0 0%;
    }
    .athira-MainHeading th,td{
        padding: 2px;
        font-size: 1rem;
    }
    .athira-MainHeading ul{
        padding: 5px 30px;
        font-size: 1rem;
    }
}