

.galleryAbumContainer{
    
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}
.album{
    width: 60%;
}

.Gallery-leftButtons{
    position: absolute;
    left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    top: 30vh;
}
.Gallery-leftButtons button{
    padding: 5px 15px;
    border-radius: 10px;
    background-color: #639c35;
    color: white;
    border: none;
    transition: 0.2s ease-in-out;
}
.Gallery-leftButtons button:hover{
    scale: 1.2;
}

.Gallery-heading h2{
   padding-left: 10%;
}

.Gallery-rightButtons{
    position: absolute;
    right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    top: 30vh;
}
.Gallery-rightButtons button{
    padding: 5px 15px;
    border-radius: 10px;
    background-color: #639c35;
    color: white;
    border: none;
    transition: 0.2s ease-in-out;
}
.Gallery-rightButtons button:hover{
    scale: 1.2;
}
.galleryDescription{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.gallery-descriptionsLeftSide{
    width: 50%;
    padding: 2% 5%;
}
.gallerydesAll{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.gallerydes{
   width: 60%; 
}

.gallerydes img{
    width: 80%;
}

@media screen and (max-width:800px) {
    .galleryAbumContainer{
        height: 40vh;
    }
    .Gallery-rightButtons{
        top:32rem
    }
    .Gallery-leftButtons{
        top:32rem
    }
    .album{
        width: 100%;
    }
    .gallery-descriptionsLeftSide{
        width: 100%;
    }
    .galleryDescription{
        display: block;
        padding: 5% 0%; 
    }
    .Gallery-heading h2{
        padding-top: 30rem;
        padding-left: 5%;
    }
    .spacer{
        height: 30rem;
    }
}


