.home-wraper{
    background-image: url(../Assets/MunnarBanner.jpg);
    background-size: cover;
    min-height: 100vh;
    height: auto;
    position: relative;
}

.home-wraper::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.home-content{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
}

.home-content h1{
    font-size: 4rem;
    font-weight: 900;
    line-height: 1;
    font-family: poppins-bold;
}
.home-content p{
    padding-top: 60px;
    width: 800px;
    font-size: 20px;
    line-height: 35px;
}
.home-content button{
    background-color:#00AEEF;
    border-radius: 1rem;
    padding: 1rem 3rem;
    border-color:#00AEEF ;
    color: white;
    margin: 1rem;
    cursor: pointer;
}

.home-content button:hover{
    background-color: transparent;
    border-color:white ;
    transition: 0.3s ease-in-out;
}

@media screen and (max-width:769px) {
    .home-content{
        padding-top: 2rem;
    }
    .home-content h1{
        font-size: 2.5rem;
        padding: 1rem 0;
    }
    .home-content p{
        width:90%;
        font-size: 1.3rem;
    }
    .Nav-menu{
        display: flex;
        list-style: none;
        padding-right: 3rem;
        gap: 2rem;
        font-family:poppins;
        }
        .home-content button{
            padding: 0.5rem 1rem;
        }
        .home-wraper{
            min-height: auto;
        }

}


@font-face {
    font-family: poppins-bold;
    src: url(../Assets/fonts/Poppins-ExtraBold.ttf);
}
