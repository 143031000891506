.AboutUs-wrapper{
    width: 100%;
    height: auto;
    background-image: url(../../Assets/AboutUsBg.svg);
    background-size: cover;
    position: relative;
}

.AboutUs-wrapper::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.AboutUs-contents{
    color: #FFFFFF;
    position: relative;
    z-index: 2;
    padding: 2rem;
}

.AboutUs-contents p{
    padding: 15px;
    font-size: 20px;
    line-height: 35px;
}

@media screen and (max-width:769px) {
    .AboutUs-contents{
        padding: 15px;
    }
    .AboutUs-contents p{
        padding: 10px;
    }
}