.Commonnav-wraper{
    display: flex;
    justify-content: space-between;
    z-index: 2;
    position: relative;
    align-items: center;
    padding: 1rem;
    background-color: #EDEADE;
    min-height: 4rem;
}

.logoimg{
    height: 3rem;
}

.CommonNav-menu{
display: flex;
list-style: none;
padding-right: 3rem;
gap: 2rem;
font-family:poppins;
}

.CommonNav-menu a{
    text-decoration: none;
    color: #36454F;
}

.nav-contactButton{
    background-color:#00AEEF;
    border-radius: 1rem;
    padding: 0 0.5rem;
}

.nav-toggle-button{
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 30px;
}

.nav-toggle-button .bar{
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
}

@media screen and (max-width:769px){
    .nav-toggle-button{
        display: flex;
    }
    .Commonnav-menus{
        display: none;
        width: 100%;
    }
    .CommonNav-menu{
        padding-top: 3rem;
        flex-direction: column;
        padding-right: 0rem;
        align-items: center;
        width: 100%;
    }
    .logo{
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .Commonnav-menus.active{
        display: flex;
    }
}