.ContactWraper{
    display: flex;
    min-height: 100vh;
    height: auto;
}

.ContactContainer{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-image: linear-gradient(to bottom, #00b09b, #96c93d);
}

.ContactContainer h1{
    font-size: 4rem;
    opacity: 0.7;
}

.ContactContainer p{
    font-size: 2rem;
    opacity: 0.7;
}

@media screen and (max-width:769px) {
    .ContactWraper{
        display: block;
    }
    .ContactContainer{
        width: 100%;
    }
    .ContactContainer h1{
        font-size: 1.5rem;
        opacity: 0.7;
        padding: 20px 10px 0 0;
    }
    
    .ContactContainer p{
        font-size: 1rem;
    }
}