
@font-face {
  font-family: dm sans;
  src: url(./Assets/fonts/DMSans-Regular.ttf);
}

@font-face {
  font-family: FrankRuhli;
  src: url(./Assets/fonts/FrankRuhlLibre-VariableFont_wght.ttf);
}

*{
  font-family: dm sans;
  padding: 0;
  margin: 0;
}