.ContactQueryWraper{
    width: 50%;
    height: auto;
    background-image: linear-gradient(to bottom, #c6ffdd, #fbd786, #f7797d);
}
.ContactQueryHeading{
    color: rgb(73, 71, 71);
    font-size: 2rem;
    display: flex;
    padding: 4rem 0 0 0;
    justify-content: center;
}
.contactquery-divider{
    display: flex;
    padding-top: 6rem;
    justify-content: center;
    align-items: center;
}
.ContactQuery-divider1{
    width: 50%;
}
.ContactQuery-divider2{
    width: 50%;
}
.ContactQueryField{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:5px 0px;
}
.ContactQuery-submit{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}
.ContactQuery-submit button{
    padding: 2px 10px;
    border-radius: 10px;
    background-color: #00A36C;
    color: white;
    border-color: whitesmoke;
    font-size: 1rem;
    cursor: pointer;
    border-width: 4px;
}

.ContactQueryField{
    color: rgb(73, 71, 71);
    font-size: 1rem;
}
.ContactQueryField input{
    opacity: 0.6;
    padding: 5px;
    font-size: 1rem;
    border-radius: 20px;
}
.ContactQueryField select{
    opacity: 0.6;
    font-size: 1rem;
    border-radius: 20px;
    border-width: 2px;
}
.ContactQueryField textarea{
    opacity: 0.6;
    padding: 5px;
    font-size: 1rem;
    border-radius: 30px;
    border-width: 2px;

}

@media screen and (max-width:769px) {
    .ContactQueryWraper{
        width: 100%;
    }
    .ContactQueryHeading{
        font-size: 1.5rem;
        padding: 20px 10px 0 0;
    }
    .contactquery-divider{
        display: block;
        padding-top: 10px;
    }
    .ContactQuery-divider1{
        width: 100%;
    }
    .ContactQuery-divider2{
        width: 100%;
    }
    .ContactQueryField{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:5px 0px;
    }
    .ContactQuery-submit{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .ContactQuery-submit button{
        padding: 2px 10px;
        border-radius: 10px;
        background-color: #00A36C;
        color: white;
        border-color: whitesmoke;
        font-size: 1rem;
        cursor: pointer;
        border-width: 4px;
    }
    
    .ContactQueryField{
        color: rgb(73, 71, 71);
        font-size: 1rem;
    }
    .ContactQueryField input{
        opacity: 0.6;
        padding: 5px;
        font-size: 1rem;
    }
    .ContactQueryField select{
        opacity: 0.6;
        font-size: 1rem;
    }
    .ContactQueryField textarea{
        opacity: 0.6;
        padding: 5px;
        font-size: 1.5rem;
        max-width: 80%;
    }
}