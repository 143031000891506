.Destinations-wrapper {
  width: 100%;
  height: auto;
  background-size: cover;
}
.destination-cards2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
    padding: 5%;
  align-items: flex-start;
  min-height: 70vh;
  background-image: linear-gradient(#83EAF1, #63A4FF);
  background-size: cover;
}
.destination-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
    padding: 5%;
  align-items: flex-start;
  min-height: 70vh;
  background-image: linear-gradient(#83EAF1, #63A4FF);
  background-size: cover;
}


.destinations-card {
  width: 350px;
  margin: 1rem 0;
  height: 200px;
  text-align: center;
  background-color: rgb(255,255,255,0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
  border-radius: 10px;
}
.destinations-card a{
  text-decoration: none;
}

.destinations-cardImg {
  width: 350px;
  height: 140px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.destination-cardButton {
  text-decoration: none;
  color: white;
  background-color: black;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 10px;
}
.destination-cardDescription {
  color: black;
  padding: 14px;
}
.destination-cardDescription h1 {
    font-size: 20px;
    line-height: 20px;
}
.destination-cardDescription h3 {
  font-size: 10px;
}

.destination-cardDescription h2 {
  font-size: 20px;
  line-height: 20px;
}
@media screen and (max-width: 769px) {
    .destination-cards{
        width: 100%;
        padding: 0%;
    }
        .destination-cards2{
        width: 100%;
        padding: 0%;
    }
  .destination-cardDescription h1 {
    font-size: 14px;
    line-height: 14px;
  }
  .destination-cardDescription h3 {
    font-size: 6px;
    line-height: 10px;
  }
  .destination-cardDescription h2 {
    font-size: 14px;
    line-height: 14px;
  }
  .destination-cardDescription {
    padding: 4px;
    margin: 0;
  }
  .destinations-card{
    width: 160px;
    height: 100px;
    margin: 10px;
  }
  .destinations-cardImg {
    width: 160px;
    height: 65px;
  }

}
