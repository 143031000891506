
.enquiryWraper{
    background-color: #6495ED;
    width: 20%;
    height: auto;
    padding: 20px 0px;
    border-radius: 10px;
    position:fixed;
    top: 20%;
    right: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
}
.enquiryField{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}

.enquiryField label{
    color: white;
    font-size: 1rem;
}
.enquiryField input{
    color: black;
    padding: 5px;
    font-size: 1rem;
    border-radius: 10px;
}
.enquiryField textarea{
    color: black;
    padding: 5px;
    font-size: 1.5rem;
    border-radius: 10px;
}
.enquiryWraper button{
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #00A36C;
  color: white;
  border-color: whitesmoke;
  font-size: 1.5rem;
  cursor: pointer;
  border-width: 4px;
}

.enquiry-submit{
    display: flex;
    justify-content: center;
    align-items: center;
  
}

@media screen and (max-width:769px) {
    .enquiryWraper{
        background-color: #6495ED;
        width: 90%;
        margin: 5%;
        height: auto;
        border-radius: 10px;
        position:unset;
    }
    .enquiryField{
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
    }
}