.LandingPage-wrapper{
    width: 100%;
    height: 42rem;
}
.LandingPageMobileBg{
    display: none;
}

.LandingPage-content{
    width: 40%;
padding-top: 5px;
margin-left: 14rem;
}
.LandingPage-content p{
    padding: 2rem 5rem 0 1rem;
    font-size: 14px;
}
.LandingPage-contentMain{
    display: flex;
}
.LandingPage-content1{
    width: 40%;
    height: 590px;
    position: relative;
    padding-left: 60px;
}
.indiaBg{
    width: 361px;
    height: 643px;
    position: absolute;
}

.indiaMain{
    width: 430px;
    height: 474px;
    margin: 105px 0 0 0;
    position: absolute;
    z-index: 1;
}
.locationIcon{
    margin: 439px 0 0 63px;
    height: 17px;
    position: absolute;
    z-index: 1;
}

.locationIcon2{
    margin: 520px 0 0 91px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon3{
    margin: 485px 0 0 143px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon4{
    margin: 476px 0 0 110px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon5{
    margin: 226px 0 0 111px;
    height: 17px;
    position: absolute;
    z-index: 1;
}

.locationIcon6{
    margin: 254px 0 0 82px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon7{
    margin: 253px 0 0 124px;
    height: 17px;
    position: absolute;
    z-index: 1;
}

.locationIcon8{
    margin: 138px 0 0 87px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon9{
    margin: 468px 0 0 13px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon10{
    margin: 468px 0 0 409px;
    height: 17px;
    position: absolute;
    z-index: 1;
}

.locationIcon11{
    margin: 183px 0 0 116px;
    height: 17px;
    position: absolute;
    z-index: 1;
}
.locationIcon12{
    margin: 192px 0 0 92px;
    height: 17px;
    position: absolute;
    z-index: 1;
}

.locationIcon:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon2:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon3:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon4:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon5:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon6:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon7:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon8:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon9:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon10:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon11:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationIcon12:hover{
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-out;
}
.locationDescription{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 457px 0 0 77px;
    background-color: rgba(245, 245, 245, 0.8);
}

.locationDescription2{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 538px 0 0 104px;
    background-color: rgba(245, 245, 245, 0.8);
}

.locationDescription3{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 503px 0 0 155px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription4{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 494px 0 0 123px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription5{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 244px 0 0 123px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription6{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 271px 0 0 96px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription7{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 273px 0 0 135px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription8{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 154px 0 0 101px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription9{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 483px 0 0 24px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription10{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 483px 0 0 424px;
    background-color: rgba(245, 245, 245, 0.8);
}

.locationDescription11{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 198px 0 0 128px;
    background-color: rgba(245, 245, 245, 0.8);
}
.locationDescription12{
    height: 0%;
    font-size: 0;
    position: absolute;
    z-index: 2;
    margin: 207px 0 0 105px;
    background-color: rgba(245, 245, 245, 0.8);
}



.locationIcon:hover + .locationDescription{
     height: 30px;
     font-size: 1rem;
     transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.locationIcon2:hover + .locationDescription2{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.locationIcon3:hover + .locationDescription3{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.locationIcon4:hover + .locationDescription4{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.locationIcon5:hover + .locationDescription5{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.locationIcon6:hover + .locationDescription6{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.locationIcon7:hover + .locationDescription7{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.locationIcon8:hover + .locationDescription8{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.locationIcon9:hover + .locationDescription9{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.locationIcon10:hover + .locationDescription10{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.locationIcon11:hover + .locationDescription11{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.locationIcon12:hover + .locationDescription12{
    height: 30px;
    font-size: 1rem;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media screen and (max-width:768px) {
    .LandingPage-wrapper{
       background-image: none;
       height: auto;
    }
    .LandingPageMobileBg{
        display: block;
        width: 100%;
    }
    .LandingPage-content{
        width: 90%;
        height: auto;
        margin-left: 12px;
    }
    .LandingPage-content p{
        padding: 2%;
        font-family:poppins;
        font-size: 12px;
    }
    .LandingPage-contentMain{
        display: block;
        height: auto;
    }
    .LandingPage-content1{
        width: 100%;
        height: 420px;
        padding-top: 0px;
        padding-left: 0px;
    }
    .indiaBg{
        width: 277px;
        height: 413px;
        position: absolute;
        padding-left: 30px;
    }
    .indiaMain{
        width: 310px;
        height: 332px;
        margin: 54px 0 0 0;
        position: absolute;
        padding-left: 30px;
        z-index: 1;
    }
    .locationIcon{
        margin: 283px 0 0 70px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    
    .locationIcon2{
        margin: 341px 0 0 90px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon3{
        margin: 321px 0 0 121px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon4{
        margin: 307px 0 0 95px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon5{
        margin: 129px 0 0 102px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon6{
        margin: 154px 0 0 94px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon7{
        margin: 163px 0 0 119px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon8{
        margin: 70px 0 0 84px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }

    .locationIcon9{
        margin: 303px 0 0 37px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon10{
        margin: 303px 0 0 295px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon11{
        margin: 101px 0 0 103px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    .locationIcon12{
        margin: 112px 0 0 87px;
        height: 17px;
        position: absolute;
        z-index: 1;
    }
    
    .locationIcon:hover{
        height: 20px;
        cursor: pointer;
        transition: 0.2s ease-out;
    }
    .locationDescription{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 300px 0 0 83px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    
    .locationDescription2{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 359px 0 0 103px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription3{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 342px 0 0 134px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription4{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 324px 0 0 106px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription5{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 150px 0 0 109px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription6{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 171px 0 0 102px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription7{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 184px 0 0 127px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription8{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 87px 0 0 97px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription9{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 314px 0 0 51px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription10{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 317px 0 0 306px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription11{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 118px 0 0 114px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    .locationDescription12{
        height: 0%;
        font-size: 0;
        position: absolute;
        z-index: 2;
        margin: 130px 0 0 99px;
        background-color: rgba(245, 245, 245, 0.8);
    }
    
    .locationIcon:hover + .locationDescription{
         height: 30px;
         font-size: 1rem;
         transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .locationIcon2:hover + .locationDescription2{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .locationIcon3:hover + .locationDescription3{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .locationIcon4:hover + .locationDescription4{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .locationIcon5:hover + .locationDescription5{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .locationIcon6:hover + .locationDescription6{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .locationIcon7:hover + .locationDescription7{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .locationIcon8:hover + .locationDescription8{
        height: 30px;
        font-size: 1rem;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}

