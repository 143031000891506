.footer-wraper{
    width: 100%;
    height: auto;
    background-color: #191970;
    color: white;
    position: absolute;
    z-index: 2;
}

.footer-quicklinks{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem ;
    color: white;
}

.footer-quicklink{
    color: white;
    text-decoration: none;
}

.footer-quicklinks1{
        display: flex; 
        gap: 2rem;
    flex-wrap: wrap
}

.footer-contact{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.footer-contact-email{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
}
.footer-address {
    display: flex;
    gap: 10px;
}
.footer-address p{
    font-size: 1rem;
}

.footer-contact-email p{
    font-size: 1rem;
}

.footer-contact-email a{
    text-decoration: none;
    color: white;
}

.footer-logo{
    padding: 1% 5%;
    display: flex;
    gap: 1rem;
}
.footer-logo p{
    font-size: 1rem;
}

.copywrite{
    display: flex;
    justify-content: center;
}
.copywrite p {
    font-size: 1rem;
}

@media screen and (max-width:769px) {
    .footer-logoImg{
        width: 200px;
    }
    .footer-logo p{
        display: none;
    }
    .footer-contact{
        display: block;
        padding: 0% 5%;
    }
.footer-quicklinks1{
        display: flex; 
        gap: 1rem;
    flex-wrap: wrap
}
}